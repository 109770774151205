.bigButton {
  font-size: var(--font-size-huge);
  border-radius: 1em;
  padding: 0.25em 0.5em;
  background-color: var(--secondary);
  border: 2px solid var(--foreground);
  color: var(--light);
  text-shadow: 1px 1px var(--dark), -1px -1px var(--dark), 1px -1px var(--dark), -1px 1px var(--dark);
  box-shadow: 3px 3px 3px var(--neutral);
  transition: background-color 100ms linear;
}

.bigButton:hover:not(:disabled) {
  background-color: var(--secondary-hover);
}

.bigButton:active:not(:disabled) {
  background-color: var(--secondary-active);
  box-shadow: 1px 1px 3px 1px var(--neutral), 2px 2px 2px inset var(--secondary), -2px -2px 2px inset var(--secondary);
}

.bigButton:disabled {
  background-color: var(--neutral);
}
