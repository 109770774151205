.letterGridContainer {
  width: 80vmin;
  height: 80vmin;
  max-width: 30rem;
  max-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.infoPanel {
  flex: 1 1 0;
  justify-content: center;
}

.title {
  margin: 0.5rem 0;
  text-align: center;
  color: var(--primary);
  text-decoration: underline;
  -webkit-user-select: none;
  user-select: none;
}

.score {
  text-align: center;
}

.gameContainer {
  display: block;
}

.bestWord {
  color: var(--neutral);
  text-align: center;
}

.rotateButton {
  margin-top: 0.5em;
  text-align: center;
}

.practisePanel {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 1rem;
  justify-content: center;
  margin: 1rem;
}

.practisePanel > :last-child {
  grid-column: 1 / span 2;
}

.bigButtonPrimary {
  font-size: var(--font-size-big);
  --secondary: var(--primary);
  --secondary-hover: var(--primary-hover);
  --secondary-active: var(--primary-active);
}

.shareButtonContainer {
  margin-top: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.shareButtonContainer > button {
  opacity: 0;
  transition: opacity 1s ease-out, transform 1s ease-out;
  transform: scale(2);
}

.shareButtonContainer.shareButtonShowing > button {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.possibleWordsTitle {
  text-align: center;
}

.possibleWordList {
  margin-top: 1rem;
  text-transform: lowercase;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.possibleWordList > * {
  text-align: center;
}

.possibleWordList.desktop {
  grid-template-columns: repeat(5, 1fr);
}

.nextScrambl {
  font-family: var(--font-mono);
}

.appear {
  opacity: 0;
  animation: appear 1s ease-out 2s forwards;
}

.noSelect {
  -webkit-user-select: none;
  user-select: none;
}

@keyframes appear {
  to {
    opacity: 1;
  }
}

@media (min-aspect-ratio: 1/1) {
  .letterGridContainer {
    margin: unset;
    margin-right: 1rem;
  }

  .gameContainer {
    display: flex;
    justify-content: center;
  }

  .gameContainer.started {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
  }

  .gameContainer.started.leftHandedMode {
    flex-direction: row;
  }
}
