.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--modal-z-index);
  display: flex;
  margin: auto;
  padding: 1rem;
}

.modal {
  margin: auto;
  min-width: 50vw;
  margin-top: 3rem;
  max-height: calc(80vh);
  border: 1px solid var(--neutral);
  border-radius: 0.2rem;
  box-shadow: 0 0 1rem 1px var(--neutral);
  padding: 1rem;
  background-color: var(--background);
  transform: translateY(3rem);
  opacity: 0;
  overflow-y: auto;
  will-change: transform, opacity, background-color;
  transition: opacity 100ms ease-out, transform 100ms ease-out, background-color 200ms linear;
}

.modal.finishedOpening {
  transform: translateY(0);
  opacity: 1;
}

.titleBar {
  position: relative;
  display: flex;
  text-transform: uppercase;
  color: var(--neutral);
  margin-bottom: 1rem;
}

.titleText {
  flex: 1 0 auto;
  text-align: center;
  color: var(--foreground);
  margin: 0;
}
