.exampleGridContainer {
  position: relative;
  display: flex;
  text-align: center;
}

.exampleGrid {
  font-size: 8vmin;
  display: grid;
  gap: 0.1em;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  margin-right: 1rem;
}

.pointerImage {
  font-size: 8vmin;
  width: 8vmin;
  height: auto;
  position: absolute;
  left: 0;
  bottom: 0.75em;
  transform: translate(1em, 0);
  transition: transform 500ms linear;
}

.spacer {
  min-height: 3rem;
}

.paragraph {
  margin-bottom: 1em;
}
