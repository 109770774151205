.letterGrid {
  flex: 1 0 auto;
  aspect-ratio: 1/1;
  display: grid;
  gap: 0.1em;
  transition: transform 250ms linear;
}

.letterGrid:not(.disabled) {
  -webkit-user-select: none;
  user-select: none;
  touch-action: none;
}

.letterGrid > div {
  width: 100%;
  height: 100%;
  min-height: 3rem;
}

.letterGrid:not(.disabled) > div {
  -webkit-user-select: none;
  user-select: none;
}
