.icon {
  display: flex;
  flex-direction: column;
}

.icon:hover {
  color: var(--foreground);
  cursor: pointer;
}

.icon:active {
  color: var(--secondary-active);
}
