.contents {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: auto;
  background-color: var(--neutral);
  row-gap: 1px;
}

.contents > * {
  background-color: var(--background);
  padding: 1rem 0;
  transition: background-color 200ms linear;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.darkModeToggleContainer {
  padding-bottom: 0;
  max-height: 5rem;
}
