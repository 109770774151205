.countdown {
  font-family: var(--font-mono);
  font-size: var(--font-size-huge);
  text-align: center;
}

.countdown.landscape {
  font-size: var(--font-size-big);
}

.timeLow {
  color: var(--warning);
}

.timeUp {
  color: var(--error);
}
