:root {
  --light: #f8f8f8;
  --dark: #292222;
  --dark-shadow: rgba(41, 34, 34, 0.5);
  --neutral: #7f7f7f;
  --background: var(--light);
  --foreground: var(--dark);
  --primary: #e900ff;
  --primary-hover: #ff30ff;
  --primary-active: #c900df;
  --secondary: #5c54ff;
  --secondary-hover: #7c74ff;
  --secondary-active: #3c34df;
  --success: #309611;
  --error-light-theme: #da0000;
  --error-dark-theme: #ff4242;
  --warning-light-theme: #aaa040;
  --warning-dark-theme: #e0e050;
  --error: var(--error-light-theme);
  --warning: var(--warning-light-theme);

  --font-normal: -apple-system, Roboto, "Segoe UI", "SF Serif", Noto, "Noto Serif", "Droid Serif", BlinkMacSystemFont, Palatino,
    "Book Antiqua", Calibri;
  --font-mono: monospace;

  --font-size-puny: 0.3rem;
  --font-size-tiny: 0.5rem;
  --font-size-small: 0.8rem;
  --font-size-normal: 1rem;
  --font-size-big: 1.33rem;
  --font-size-huge: 2.5rem;
  --font-size-massive: 3rem;
  --font-size-tinier: 0.5em;
  --font-size-smaller: 0.8em;
  --font-size-bigger: 1.33em;

  --max-width: 1000px;

  --menu-z-index: 5;
  --modal-z-index: 10;

  --is-desktop: 0;
  --is-landscape: 0;
  color: var(--foreground);
  background-color: var(--background);
  transition: background-color 200ms linear, color 16ms linear;
  font-size: 16px;
  color: transparent;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

body {
  margin: 0;
  font-family: var(--font-normal);
  background-color: var(--background);
  color: var(--foreground);
  transition: background-color 200ms linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#root {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: var(--max-width);
  margin: auto;
  margin-top: 0;
  padding: 1rem;
  flex: 1 0 auto;
  border-left: 1px solid var(--neutral);
  border-right: 1px solid var(--neutral);
}

.desktop#root {
  width: min(100vw, var(--max-width));
}

* {
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

address {
  font-style: inherit;
}

a {
  text-decoration: none;
  color: var(--primary);
}

a:visited {
  color: var(--primary-active);
}

a:hover {
  color: var(--primary-hover);
}

a:active {
  color: var(--primary-active);
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 0.25em;
}

h4 {
  margin-top: 0.5em;
}

p {
  margin-top: 0.25em;
  line-height: 1.5em;
}

hr {
  height: 0;
  border: 0;
  border-top: 1px dotted var(--neutral);
}

pre {
  margin: 0;
}

::placeholder {
  opacity: 0.35;
  font-size: var(--font-size-normal);
}

.muted {
  font-size: var(--font-size-smaller);
  color: var(--neutral);
}

.error {
  color: var(--error);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark);
    --foreground: var(--light);
    --error: var(--error-dark-theme);
    --warning: var(--warning-dark-theme);
  }
}

@media screen and (min-width: 20cm) {
  :root {
    --is-desktop: 1;
    color: var(--foreground);
  }
}

@media screen and (min-aspect-ratio: 1/1) {
  :root {
    --is-landscape: 1;
    color: var(--foreground);
  }
}
