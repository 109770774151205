.menuBarWrapper {
  z-index: var(--menu-z-index);
  font-size: 0.75em;
  color: var(--neutral);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--neutral);
}

.menuBarWrapper svg {
  width: 1.5em;
  height: 1.5em;
}

.menuBarStart,
.menuBarEnd {
  flex: 1 0 0;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto auto 1fr;
}

.menuBarEnd {
  grid-template-columns: 1fr auto auto;
}

.appTitle {
  font-family: var(--font-mono);
  font-size: var(--font-size-bigger);
  text-align: center;
  flex: 0 1 auto;
  color: var(--foreground);
}

.desktop.menuBarWrapper {
  font-size: 1.25em;
}

.desktop.menuBar {
  margin: auto;
  max-width: 30rem;
  padding: 1em 0;
}
