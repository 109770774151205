.statsContainer {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: auto;
  background-color: var(--neutral);
  row-gap: 1px;
}

.statsContainer > * {
  background-color: var(--background);
  padding-top: 1rem;
  transition: background-color 200ms linear;
}

.statsContainer > strong {
  text-align: end;
}

.padRight {
  padding-right: 1em;
}

.averageScore {
  color: var(--success);
}
