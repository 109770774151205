.shareButton {
  font-size: var(--font-size-big);
  border-radius: 0.1em;
  padding: 0.25em 0.5em;
  background-color: var(--secondary);
  border: 2px solid var(--foreground);
  color: var(--light);
  box-shadow: 3px 3px 3px var(--neutral);
  transition: background-color 100ms linear;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  user-select: none;
}

.shareButton:hover {
  background-color: var(--secondary-hover);
}

.shareButton:active {
  background-color: var(--secondary-active);
  box-shadow: 1px 1px 3px 1px var(--neutral), 2px 2px 2px inset var(--secondary), -2px -2px 2px inset var(--secondary);
}
