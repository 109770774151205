.tile {
  border: 2px solid var(--neutral);
  background-color: transparent;
  font-family: var(--font-mono);
  font-weight: bold;
  width: 1em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  transform: scaleY(1);
  transition: transform 50ms linear, background-color 250ms ease-out;
  display: flex;
  position: relative;
}

.rotationContainer {
  transform: rotate(0deg);
  transition: transform 250ms linear;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile > * {
  font-size: var(--font-size-smaller);
}

.tile.flipping {
  transform: scaleY(0);
}

.tile.flipped {
  background-color: var(--secondary);
  color: var(--light);
  transform: scaleY(1);
  border-width: 0;
  cursor: pointer;
}

.tile.flipped.success {
  animation: unset-background-success 0.75s linear, pulse 0.75s ease-out;
}

.tile.flipped.duplicate {
  animation: unset-background-warning 0.75s linear;
}

.tile.flipped.failure {
  animation: unset-background-error 0.75s linear;
}

.tile.flipped.active {
  background-color: var(--primary);
  animation: unset;
}

.tile.flipped.disabled {
  background-color: var(--neutral);
  cursor: default;
}

.tile.flipped.disabled.highContrast {
  color: #000000;
}

.additionalCharacters {
  font-size: var(--font-size-smaller);
}

.tileText {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.score {
  display: inline-block;
  font-size: 0.3333em;
  font-weight: normal;
  transform: translate(0.1em, 0.2em);
  position: absolute;
  right: 0.125em;
  bottom: 0.125em;
}

@keyframes unset-background-success {
  0% {
    background-color: var(--success);
  }

  50% {
    background-color: var(--success);
  }

  100% {
    background-color: var(--secondary);
  }
}

@keyframes unset-background-warning {
  0% {
    background-color: var(--warning);
  }

  50% {
    background-color: var(--warning);
  }

  100% {
    background-color: var(--secondary);
  }
}

@keyframes unset-background-error {
  0% {
    background-color: var(--error);
  }

  50% {
    background-color: var(--error);
  }

  100% {
    background-color: var(--secondary);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.15, 1.15);
    opacity: 0.35;
    border-radius: 0.5em;
  }

  50% {
    opacity: 1;
    border-radius: 0.5em;
  }

  100% {
    transform: scale(1, 1);
    opacity: 1;
    border-radius: 0;
  }
}
