.contents {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  background-color: var(--neutral);
  row-gap: 1px;
}

.contents > * {
  display: block;
  background-color: var(--background);
}
